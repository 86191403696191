<template>
   <LiefengTable
     :talbeColumns="talbeColumns"
      :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[10, 20, 30, 50]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
  ></LiefengTable>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: ["info"],
  data() {
    return {
      talbeColumns: [
        {
          title: "服务成效",
          key: "seviceEffect",
          minWidth: 150,
          align: "center",
        },
        {
          title: "评估人",
          key: "assessor",
          minWidth: 150,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 180,
          align: "center",
        },
      ],
       tableData: [],
      page: 1,
      loading: false,
      pageSize: 20,
      total: 0
    };
  },
  methods: {
    hadlePageSize(obj) {
      this.loading = true;
      this.$post('/archives/api/pc/serviceAssess/queryAssessByPage',{
        archiveId: this.info,
        page: obj.page,
        pageSize: obj.pageSize
      }).then(res => {
        if(res.code == 200 && res.data.serviceAssessPages.code == 200 && res.data.serviceAssessPages.dataList) {
          res.data.serviceAssessPages.dataList.map(item => {
            if(item.gmtCreate) {
             item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate),"yyyy-MM-dd")
            }
          })
          this.tableData = res.data.serviceAssessPages.dataList;
          this.page = res.data.serviceAssessPages.currentPage;
          this.total = res.data.serviceAssessPages.maxCount;
          this.pageSize = res.data.serviceAssessPages.pageSize;
           this.loading = false;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    }
  },
  created() {
        this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      })
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>